<template>
  <v-dialog
    v-model="viewFile"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    persistent
    max-width="1000"
    transition="dialog-transition"
    scrollable
  >
    <v-card outlined>
      <v-card-title class="mb-3">
        <div style="position: absolute; right: 25px;">
          <v-icon @click="close()">
            mdi-close
          </v-icon>
        </div>
      </v-card-title>
      <v-card-text>
        <v-row justify="center" align="center" no-gutters>
          <v-img v-if="validateImage(urlFile)" :src="urlFile" contain />
          <iframe
            v-else
            :src="viewDoc(urlFile)"
            :height="$vuetify.breakpoint.xsOnly ? '700vh' : '500px'"
            width="100%"
          />
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          class="gradient-primary"
          dark
          depressed
          @click="openLink(urlFile)"
        >
          <v-icon class="mr-2">mdi-open-in-new</v-icon>
          {{ $t("e_test.open_file") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { isImageFile } from "@/utils/validate";

export default {
  props: {
    viewFile: Boolean,
    urlFile: String
  },
  methods: {
    close() {
      this.$emit("close");
    },
    validateImage: name => isImageFile(name),
    openLink: url => window.open(url, "_blank"),
    viewDoc(name) {
      if (/\.(pdf)$/i.test(name)) {
        return `https://docs.google.com/viewer?url=${name}&embedded=true`;
      } else {
        return `https://view.officeapps.live.com/op/view.aspx?src=${name}`;
      }
    }
  }
};
</script>
